import React from 'react';
import './LandingPage.css'; // Import your CSS file here


function FireFlies() {
    return (
        <div>
           

            <ul className="fireflies">

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

                <li></li>

            </ul>
        </div>
    );
}

export default FireFlies;
