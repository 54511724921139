import React, { useState, useEffect } from 'react';
import LandingPage from './components/LandingPage';
import LeftBox from './components/LeftBox';
import FireFlies from './components/FireFlies';
import Form from './components/Form';
import './App.css';
import './components/LandingPage.css';

function App() {
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    const formElement = document.getElementById('form-container');
    if (formElement && formVisible) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formVisible]);

  const scrollToForm = () => {
    setFormVisible(true);
  };

  return (
    <div className="App">
      <div className="MainContainer">
        <div className="container">
          <LeftBox scrollToForm={scrollToForm} />
          <FireFlies />
          <LandingPage />
        </div>
        {formVisible ? (
          <div id="form-container" className="FormContainer">
            <Form />
          </div>
        ) : (
          <div>
            {/* Button rendering is now handled in the LeftBox component */}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
