import React from 'react';
import './LandingPage.css'; // Import your CSS file here
import Audio from './Audio';


function LandingPage() {
  return (
   
      
        <div className="right-panel">
           
          <div className="image-container">
          <div className="headerMobile" >
         
          <h1>Something Moves</h1>
          </div>
            <img className="faded-image" src="/your-image.jpg" alt="Image Description" />
          </div>
        </div>
  );
}

export default LandingPage;
